.locl-base-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  
  width: 100%;
  padding: 0 24px;
  height: 72px;

  align-items: center;
  display: flex;
  justify-content: flex-start;
  grid-gap: 24px;

  &__main-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 24px;
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    margin-left: auto;
  }

  &__mobile-menu-button {
    cursor: pointer;
    height: 32px;
    width: 32px;

    color: inherit;
    
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__mobile-drawer {
    :global {
      .ant-drawer-header { display: none; }
      .ant-drawer-body { padding: 0; } 
    }
  }

  .locl-auth-header__image-logo {
    width: auto;
    height: 70px;
  }
}
