.locl-error-page {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: calc(100vh - 120px);
  padding-top: 100px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  
  overflow: hidden;

  &__description-block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    grid-gap: 42px;
    padding-bottom: 100px;
  }

  &__description-block-header {
    color: rgb(254, 97, 97);
    font-size: 115px;
    font-weight: 700;
    line-height: 94px;

    margin: 0;
  }

  &__description-block-description {
    font-size: 45px;
    font-weight: 700;
    color: rgb(37, 37, 37);
    line-height: 55px;
    
    margin: 0;
  }

  &__description-block-sub-description {
    color: rgb(164, 164, 164);
    font-size: 20px;
    font-weight: 400;
  }

  &__image-block {
    position: relative;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 30%;
      background: rgb(254, 97, 97);
    }
  }

  &__image-block-image {
    object-fit: cover;
    width: auto;
    height: 90%;

    top: 10%;
    position: absolute;
    z-index: 2;
  }
  
  &.locl-error-page__coming-soon {
    .locl-error-page {
      &__description-block-header {
        color: rgba(37, 37, 37, 1);
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .locl-error-page {
    position: relative;
    padding-left: 40px;
    grid-template-rows: minmax(calc(100vh - 120px), max-content);

    &__description-block { z-index: 3; }
  }
}

@media screen and (max-width: 1024px) {
  .locl-error-page {
    &__image-block {
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 767px) {
  .locl-error-page {
    padding-top: 48px;

    &.locl-error-page__coming-soon {
      .locl-error-page {
        &__description-block-header {
          font-size: 64px;
          line-height: 1.15;
        }

        &__description-block-description {
          font-size: 24px;
          line-height: 1.25;      
        }
      }
    }
  }
}
